import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  H3,
  getSpace,
  Text,
  Flex,
  SpinnerIcon,
  Button,
  Span,
  getBreakpoint,
  getColor,
} from "@fatlama/llama-library";
import {
  AdminEventType,
  GetCMSRentalPageResponse,
  OrderDataType,
} from "../../interfaces";
import dayjs from "dayjs";
import GetApi from "../../utils/api";
import { path, isEmpty, indexBy, prop } from "ramda";

import { useParams, useLocation, useHistory } from "react-router-dom";
import { Page, DuplicatedUserType } from "../../interfaces";
import HeaderSection, { Tab } from "./HeaderSection";
import {
  SectionBlock,
  DataRow,
  DoubleDataRow,
  SectionCollapsableBlock,
} from "./Components";
import formatWithCurrency from "../../helpers/formatWithCurrency";
import OtherRentals from "./OtherRentals";
import Documents from "../Documents/Index";
import DuplicatesDocuments from "../Documents/DuplicateDocuments";
import ApprovalSection from "./ApprovalSection";
import UserDetails from "./UserDetails";
import CommunicationsAndNotes from "./CommunicationsAndNotes";
import CurrentRentalItems from "./CurrentRentalItems";
import useUserVerifications from "./hooks/useUserVerifications";
import useOtherRentals from "./hooks/useOtherRentals";
import useUsers from "./hooks/useUsers";
import useDuplicates from "./hooks/useDuplicates";
import useUserSearches from "./hooks/useUserSearches";
import DuplicatesList from "./Duplicates";
import UserFlaggedBanner from "../Common/UserFlaggedBanner";
import { NAV_WIDTH } from "../NavSideBar/Index";
import ScrollHideHeader from "../../hooks/ScrollHideHeader";
import utc from "dayjs/plugin/utc";
import StripeData from "./StripeData";
import RentalAbilityPauseSection from "./RentalAbilityPauseSection";
import OrderVerificationComponent from "./OrderVerification";
import AdminEvents from "./AdminEvents";
import UserStatsComponent from "./UserStats";
import { getTimeSince } from "../Rentals/utils";

dayjs.extend(utc);

const MapPin = styled.div`
  z-index: 1;
  width: 40px;
  height: 40px;
  @media (min-width: ${getBreakpoint(3)}) {
    width: 70px;
    height: 70px;
  }
  background-color: ${getColor("whale")}33; // 0.2 opacity
  border: 1px solid ${getColor("whale")};
  border-radius: 100px;
`;

const MapImage = styled.img`
  width: 100%;
  border-radius: 16px;
  object-fit: contain;
`;

const StripeLogo = styled.img`
  width: 65px;
`;

const PageContainer = styled(Box)`
  transition: padding 0.7s ease;
`;

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const TwoGrid = styled(Box)`
  display: grid;
  grid-column-gap: ${getSpace(3)};
  grid-template-columns: 1fr 1fr;
`;
const SelectedRental = () => {
  ScrollHideHeader("pageContainer");

  const [currentOrder, setCurrentOrder] = useState<GetCMSRentalPageResponse>();

  const borrowerId = path(["order", "customerId"], currentOrder);
  const query = new URLSearchParams(useLocation().search);

  const history = useHistory();

  const refresh = Boolean(query.get("refresh"));
  const {
    userVerifications,
    userDocuments,
    loadingUserVerifications,
  } = useUserVerifications(borrowerId, currentOrder?.order.orderId, refresh);

  const { otherRentals } = useOtherRentals(borrowerId);

  const {
    userSearches,
    loadingUserSearches,
    showMore,
    canShowMore,
  } = useUserSearches(borrowerId);
  const duplicates = useDuplicates(borrowerId);
  const userDetails = useUsers({
    lenderId: currentOrder && currentOrder.order.ownerId,
    borrowerId: currentOrder && currentOrder.order.customerId,
  });

  const [activeTab, setActiveTab] = useState(Tab.TRANSACTION);
  const [showUpcomingTx, setShowUpcomingTx] = useState(false);
  const api = GetApi();
  let { idOrOfferUuid } = useParams() as any;
  const { accounts, selfReferrer } = duplicates;

  const hasDuplicateFlaggedDetails =
    accounts &&
    Boolean(
      Object.values(accounts).find(
        (account: DuplicatedUserType) =>
          account.tags && account.tags.hasFlaggedDetails
      )
    );

  const hasDuplicateAutoApproveBlocked =
    accounts &&
    Boolean(
      Object.values(accounts).find(
        (account: DuplicatedUserType) =>
          account.tags && account.tags.isFlaggedDodgy
      )
    );

  const [headerHeight, setheaderHeight] = useState(90);

  const getAndSetHeight = () => {
    const header = document.getElementById("header");
    if (header) {
      setheaderHeight(header.offsetHeight);
    }
  };

  useEffect(() => {
    getAndSetHeight();
    window.addEventListener("resize", getAndSetHeight);
    return () => {
      window.removeEventListener("resize", getAndSetHeight);
    };
  }, []);

  useEffect(() => {
    getAndSetHeight();
  }, [
    currentOrder,
    hasDuplicateFlaggedDetails,
    hasDuplicateAutoApproveBlocked,
  ]);

  const getRental = async () => {
    // const getRental = async () => {
    const response = await api.getRental({ idOrOfferUuid });

    const formatted = {
      ...response.payload,
      overlappingOrders: indexBy(
        prop("orderId"),
        response.payload.overlappingOrders
      ),
      upcomingOrders: indexBy(prop("orderId"), response.payload.upcomingOrders),
    };
    setCurrentOrder(formatted);
    // }
  };
  useEffect(() => {
    if (refresh) {
      getRental();
      history.push(`${Page.rentals}/${idOrOfferUuid}`, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (!currentOrder || Number(idOrOfferUuid) !== currentOrder.order.orderId) {
      getRental();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, idOrOfferUuid]);

  if (!currentOrder) {
    return (
      <FullScreenCentered>
        <SpinnerIcon />
      </FullScreenCentered>
    );
  }

  const { order, overlappingOrders, upcomingOrders } = currentOrder;

  const totalValueOfOverlapping = overlappingOrders
    ? Object.keys(overlappingOrders).reduce(
        (acc: number, idOrOfferUuid: any) => {
          const overlappingOrder = overlappingOrders[idOrOfferUuid];
          return acc + overlappingOrder.totalEstimatedValue;
        },
        0
      )
    : 0;

  const totalValueOfItemsRequested =
    totalValueOfOverlapping + order.totalEstimatedValue;

  const approved = (currentOrder.order.adminEvents || []).find(
    (event) => event.type === AdminEventType.ORDER_APPROVED
  );
  const rejected = (currentOrder.order.adminEvents || []).find(
    (event) => event.type === AdminEventType.ORDER_REJECTED
  );

  const highestValueCOMPLETEDRental = (otherRentals || []).reduce(
    (acc: number, rental: OrderDataType) => {
      return rental.orderStatus === "COMPLETED" &&
        rental.totalEstimatedValue > acc
        ? rental.totalEstimatedValue
        : acc;
    },
    0
  );

  return (
    <>
      <Box position="fixed" zIndex={10} width={`calc(100% - ${NAV_WIDTH}px)`}>
        <UserFlaggedBanner
          rentalAbilityPause={order.rentalAbilityPause}
          duplicateAccounts={duplicates.accounts}
        />
      </Box>

      <PageContainer
        bg="#ggg"
        position="relative"
        pt={order.rentalAbilityPause ? "60px" : 0}
      >
        <HeaderSection
          activeTab={activeTab}
          idOrOfferUuid={idOrOfferUuid}
          setActiveTab={setActiveTab}
          order={order}
          borrower={userDetails && userDetails.borrower}
          lender={userDetails && userDetails.lender}
          totalValueOfItemsRequested={totalValueOfItemsRequested}
          fraudScore={0}
          selfReferrer={selfReferrer}
        />
        <Box pt={headerHeight + 24} px={3} minHeight="100vh">
          {activeTab === Tab.COMMS ? (
            <CommunicationsAndNotes order={order} />
          ) : (
            <>
              <TwoGrid>
                <SectionBlock>
                  <H3>Rental Details</H3>
                  <DoubleDataRow
                    title="Requested at"
                    value={dayjs(order.createdAt)
                      .utc()
                      .format("D MMM YY HH:mm")}
                    value2={getTimeSince(order.createdAt)}
                  />

                  <DoubleDataRow
                    title="Paid at"
                    value={
                      order.paymentStatusUpdatedAt &&
                      (order.paymentStatus === "FUNDS_RESERVED" ||
                        order.paymentStatus === "FULLY_PAID")
                        ? dayjs(order.paymentStatusUpdatedAt)
                            .utc()
                            .format("D MMM YY HH:mm")
                        : "N/A"
                    }
                    value2={
                      order.paymentStatusUpdatedAt &&
                      (order.paymentStatus === "FUNDS_RESERVED" ||
                        order.paymentStatus === "FULLY_PAID")
                        ? getTimeSince(order.paymentStatusUpdatedAt)
                        : "N/A"
                    }
                  />

                  <DoubleDataRow
                    title="Time customer waiting for review"
                    value={
                      order.securityStatus === "IN_MANUAL_REVIEW" &&
                      order.timeCustomerWaitingForReview
                        ? dayjs(order.timeCustomerWaitingForReview)
                            .utc()
                            .format("D MMM YY HH:mm")
                        : "N/A"
                    }
                    value2={
                      order.timeCustomerWaitingForReview
                        ? getTimeSince(order.timeCustomerWaitingForReview)
                        : "N/A"
                    }
                  />

                  <DataRow
                    bg="gsMist"
                    title="From Date"
                    value={
                      order.fromDate &&
                      dayjs(order.fromDate).utc().format("D MMM YY ")
                    }
                  />
                  <DataRow
                    title="To Date"
                    value={
                      order.toDate &&
                      dayjs(order.toDate).utc().format("D MMM YY ")
                    }
                  />
                  <DataRow title="Rental status" value={order.orderStatus} />
                  <DataRow title="Payment status" value={order.paymentStatus} />
                  <DataRow
                    title="Security status"
                    value={order.securityStatus}
                  />
                  <DataRow
                    title="Delivery status"
                    value={order.deliveryStatus}
                  />

                  {rejected && (
                    <DataRow
                      bg="gsMist"
                      title="Rejected by"
                      value={rejected.adminName}
                    />
                  )}
                  {approved && (
                    <DataRow title="Approved by" value={approved.adminName} />
                  )}

                  {/* <DataRow
                  title='Requested at'
                  value={dayjs(order.createdAt).utc().format('D MMM YY HH:mm')}
                /> */}
                  <DataRow
                    title={order.items.length > 1 ? "Item IDs" : "Item ID"}
                    value={order.items
                      .map((item: any) => item.itemId)
                      .join(", ")}
                  />
                </SectionBlock>
                <Box>
                  <SectionBlock>
                    <H3>Pricing Breakdown</H3>
                    <DataRow
                      title="Volume/GMV"
                      value={
                        order.orderFullTotal > 0
                          ? formatWithCurrency(
                              order.orderFullTotal,
                              order.currency
                            )
                          : "-"
                      }
                    />
                    <DataRow
                      bg="gsMist"
                      title="Discount"
                      value={
                        order.totalDiscount > 0
                          ? formatWithCurrency(
                              order.totalDiscount,
                              order.currency
                            )
                          : "-"
                      }
                    />
                    {/* <DataRow
                      title="Promocode"
                      value={
                        order.originalPromocode
                          ? order.originalPromocode.toUpperCase()
                          : "-"
                      }
                    />
                    <DataRow
                      bg="gsMist"
                      title="Duplicate used promocodes"
                      value={
                        order.originalPromocode &&
                        selfReferrer &&
                        selfReferrer.referrerUsedPromocodes ? (
                          <Span
                            color={
                              selfReferrer.referrerUsedPromocodes.includes(
                                order.originalPromocode.toUpperCase()
                              )
                                ? "pomMute"
                                : "copyOne"
                            }
                          >
                            {selfReferrer.referrerUsedPromocodes.join(" ")}
                          </Span>
                        ) : (
                          "-"
                        )
                      }
                    /> */}

                    {/* <DataRow
                      alert={order.creditUsed > 0}
                      title="Credit Used"
                      value={
                        order.creditUsed > 0
                          ? formatWithCurrency(order.creditUsed, order.currency)
                          : "-"
                      }
                    /> */}
                    <DataRow
                      bg="gsMist"
                      title="Renter fees"
                      value={
                        order.customerFee > 0
                          ? formatWithCurrency(
                              order.customerFee,
                              order.currency
                            )
                          : "-"
                      }
                    />
                    <DataRow
                      title="Owner fees"
                      value={
                        order.ownerFee > 0
                          ? formatWithCurrency(order.ownerFee, order.currency)
                          : "-"
                      }
                    />
                    <DataRow
                      bg="gsMist"
                      title="Net Revenue"
                      value={
                        order.ourRevenue > 0
                          ? formatWithCurrency(order.ourRevenue, order.currency)
                          : "-"
                      }
                    />
                    <DataRow
                      title="Owner Earnings"
                      value={
                        order.ownerEarnings > 0
                          ? formatWithCurrency(
                              order.ownerEarnings,
                              order.currency
                            )
                          : 0
                      }
                    />

                    <a href={`https://admin.hygglo.se/Order/${idOrOfferUuid}`}>
                      <DataRow
                        title="Change dates"
                        value={
                          <Span link color="whale" small>
                            Done in admin - click here
                          </Span>
                        }
                      />
                    </a>
                  </SectionBlock>
                </Box>
              </TwoGrid>

              <SectionCollapsableBlock Title={<H3>Customer Details</H3>}>
                <UserDetails
                  {...userDetails}
                  highestValueCOMPLETEDRental={highestValueCOMPLETEDRental}
                />
              </SectionCollapsableBlock>

              <UserStatsComponent stats={currentOrder.order.stats} />

              <SectionCollapsableBlock Title={<H3>Duplicate accounts</H3>}>
                <DuplicatesList
                  userId={userDetails?.borrower?.id}
                  rentalAbilityPause={order.rentalAbilityPause}
                  duplicates={{ accounts, selfReferrer }}
                  isLoading={false}
                />
              </SectionCollapsableBlock>

              <SectionCollapsableBlock
                Title={<StripeLogo src="/images/stripe.png" />}
              >
                <StripeData transactionId={order.orderId} />
              </SectionCollapsableBlock>

              {/* {!isEmpty(overlappingOrders) && ( */}
              <SectionBlock>
                <Box mb={3}>
                  <Flex>
                    <H3>
                      Item{order.items.length > 1 ? "s" : ""} in this rental
                    </H3>
                  </Flex>

                  <CurrentRentalItems order={order} />
                </Box>

                {!isEmpty(overlappingOrders) && (
                  <Box mb={3}>
                    <H3>Overlapping rentals</H3>
                    <OtherRentals orders={overlappingOrders} />
                  </Box>
                )}

                {!isEmpty(overlappingOrders) && (
                  <Flex justifyContent="flex-end">
                    <Flex width="auto">
                      <Text bold>Total items value: </Text>

                      <Text width="90px" px={2} bold color="pomNeon">
                        {formatWithCurrency(
                          totalValueOfItemsRequested,
                          order.currency
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                )}

                {!isEmpty(upcomingOrders) ? (
                  showUpcomingTx ? (
                    <Box>
                      <H3>Other Upcoming rentals</H3>
                      <OtherRentals
                        orders={indexBy(prop("orderId"), upcomingOrders)}
                      />
                    </Box>
                  ) : (
                    <Text
                      mt={3}
                      textAlign="right"
                      link
                      onClick={() => setShowUpcomingTx(true)}
                    >
                      Show other upcoming txs
                    </Text>
                  )
                ) : null}
              </SectionBlock>
              {/* )} */}

              {!isEmpty(otherRentals) ? (
                <SectionCollapsableBlock
                  Title={
                    <H3
                      onClick={() => {
                        history.push(`/customer-rentals/${borrowerId}`);
                      }}
                    >
                      All other orders as Customer (click me)
                    </H3>
                  }
                >
                  <Box>
                    <OtherRentals
                      isCustomerOtherRentals
                      orders={indexBy(prop("orderId"), otherRentals)}
                    />
                  </Box>
                </SectionCollapsableBlock>
              ) : null}

              <CommunicationsAndNotes order={order} />

              <AdminEvents events={order.adminEvents} />

              <SectionCollapsableBlock Title={<H3>Renter Requirements</H3>}>
                {loadingUserVerifications && !userVerifications ? (
                  <SpinnerIcon />
                ) : (
                  <Documents
                    orderId={order.orderId}
                    userId={order.customerId}
                    userVerifications={userVerifications}
                    userDocuments={userDocuments}
                  />
                )}
              </SectionCollapsableBlock>

              <SectionCollapsableBlock
                initiallyClosed
                Title={<H3>Duplicate documents</H3>}
              >
                <DuplicatesDocuments duplicates={{ accounts, selfReferrer }} />
              </SectionCollapsableBlock>

              <OrderVerificationComponent
                orderVerification={order.orderVerification}
              />
              {/* 
              <SectionCollapsableBlock Title={<H3>Renter Other Documents</H3>}>
                {loadingUserVerifications && !userVerifications ? (
                  <SpinnerIcon />
                ) : (
                  <UserDocuments userDocuments={userDocuments} />
                )}
              </SectionCollapsableBlock> */}

              {/* {!isEmpty(order.verifLogs) ? (
                <SectionBlock>
                  <Flex justifyContent='space-between'>
                    <H3>Auto Approve Results </H3>
                    <H3 bold color={order.verifLogs.blockAutoApprove ? 'pomMute' : 'marineMute'}>
                      Auto approve blocked: {order.verifLogs.blockAutoApprove.toString()}
                    </H3>
                  </Flex>
                  <Hr mb={3} />

                  <TwoGrid>
                    <Box>
                      <Text bold>Any of these will block auto approve</Text>
                      {Object.keys(order.verifLogs.results).map((key: any) => {
                        return (
                          <Text
                            small
                            color={!order.verifLogs.results[key] ? 'marineMute' : 'pomMute'}
                          >
                            {key.replaceAll('_', ' ').toLowerCase()}:{' '}
                            {order.verifLogs.results[key].toString()}
                          </Text>
                        )
                      })}
                    </Box>

                    <Box>
                      <Text bold mb={0}>
                        "Is deemed risky" breakdown
                      </Text>
                      <Text small>(A combination of these may cause "is deemed risky": true)</Text>
                      {Object.keys(order.verifLogs.isDeemedRiskyResult).map((key: any) => {
                        return (
                          <Text
                            small
                            color={
                              !order.verifLogs.isDeemedRiskyResult[key] ? 'marineMute' : 'pomMute'
                            }
                          >
                            {key.replaceAll('_', ' ').toLowerCase()}:{' '}
                            {order.verifLogs.isDeemedRiskyResult[key].toString()}
                          </Text>
                        )
                      })}
                    </Box>
                  </TwoGrid>
                </SectionBlock>
              ) : null} */}
              {/*
              <SectionBlock>
                <H3>
                  Security Rules -{" "}
                  {!isEmpty(order.verifLogs) &&
                  has("seonApproves", order.verifLogs) ? (
                    order.verifLogs.seonApproves ? (
                      <Span bold color="marineMute">
                        Auto approved
                      </Span>
                    ) : (
                      <Span bold color="pomMute">
                        {" "}
                        Auto approve blocked
                      </Span>
                    )
                  ) : (
                    ""
                  )}
                </H3>
                <SecurityRules data={seonData} />
              </SectionBlock> */}

              {/* <SectionCollapsableBlock
                initiallyClosed={true}
                Title={<H3>Seon - Input data</H3>}
              >
                <SeonInput data={seonData} />
              </SectionCollapsableBlock> */}

              {/* <SectionCollapsableBlock
                // initiallyClosed={seonData.fraudResponse ? true : false}
                Title={<H3>Seon - Phone, Email, IP Checks</H3>}
              >
                <SeonData data={seonData} />
              </SectionCollapsableBlock> */}

              <SectionCollapsableBlock Title={<H3>Item location</H3>}>
                <Flex position="relative">
                  <Flex
                    height="100%"
                    position="absolute"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <MapPin />
                  </Flex>

                  <MapImage
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${order.items[0].lat},${order.items[0].lng}&zoom=11&size=800x200
          &key=AIzaSyANWfzTlV-cyaFb2hIZru5Yw6jpcFASOwo`}
                  />
                </Flex>
              </SectionCollapsableBlock>

              <SectionCollapsableBlock Title={<H3>Searches</H3>}>
                {loadingUserSearches && !userSearches.length ? (
                  <SpinnerIcon />
                ) : (
                  <>
                    <Flex mb={2}>
                      <Text small mb={0} width={1 / 3}></Text>
                      <Text small color="copyTwo" bold mb={0} width={1 / 3}>
                        Search Date
                      </Text>
                      <Text small color="copyTwo" bold mb={0} width={1 / 3}>
                        Search from & to Date
                      </Text>
                    </Flex>
                    {userSearches.map((search, i) => (
                      <DoubleDataRow
                        bg={i % 2 !== 0 ? "gsMist" : "white"}
                        title={search.searchTerm}
                        value={dayjs(search.createdAt)
                          .utc()
                          .format("D MMM YY, HH:mm")}
                        value2={
                          search.fromDate &&
                          search.toDate &&
                          `${dayjs(search.fromDate)
                            .utc()
                            .format("D MMM YY, HH:mm")} - ${dayjs(search.toDate)
                            .utc()
                            .format("D MMM YY, HH:mm")}}`
                        }
                      />
                    ))}

                    {canShowMore && (
                      <Button
                        mr={3}
                        mt={2}
                        variant="secondary"
                        large
                        label="See more"
                        onClick={() => showMore()}
                      />
                    )}
                  </>
                )}
              </SectionCollapsableBlock>

              <SectionBlock>
                <ApprovalSection order={order} />
              </SectionBlock>

              <SectionBlock>
                <RentalAbilityPauseSection
                  order={order}
                  // underReviewDocs={[]}
                  // underReviewDocs={userVerifications?.underReview}
                />
              </SectionBlock>
            </>
          )}
        </Box>
      </PageContainer>
    </>
  );
};

export default SelectedRental;
